<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        {{ page_title }}
      </h3>

      <div class="form-group mt-3" v-if="!sellerTaskID">
        <input type="radio" class="btn-check" name="options-outlined" id="owner-client"
               @input="form.owner_type = 'client'" autocomplete="off" :checked="form.owner_type === 'client'">
        <label class="btn btn-outline-primary" for="owner-client">Авто клиента</label>

        <input type="radio" class="btn-check" name="options-outlined" id="owner-company"
               @input="() => {
                 form.owner_type = 'company';
                 form.location = 'showroom';
               }" autocomplete="off" :checked="form.owner_type === 'company'">
        <label class="btn btn-outline-primary" for="owner-company">Авто компании</label>

        <input type="radio" class="btn-check" name="options-outlined" id="owner-exchange"
               @input="() => {
                 form.owner_type = 'exchange';
                 form.location = 'owner';
               }" autocomplete="off" :checked="form.owner_type === 'exchange'">
        <label class="btn btn-outline-primary" for="owner-exchange">Обмен/выкуп</label>
      </div>
    </div>

    <div class="box-body">
      <transition name="fade">
        <form @keydown="form.onKeydown($event)" @submit.prevent="saveCar" v-if="onProgress === false">

          <div class="form-group">
            <div class="row">
              <div class="col-12">
                <label class="font-medium font-small">
                  Тип договора
                </label>
                <div class="form-group mt-1">
                  <input type="radio"
                         class="btn-check"
                         name="options-outlined-contract_type"
                         id="contract_type-free"
                         @input="form.contract_type = 'free'"
                         autocomplete="off"
                         :checked="form.contract_type === 'free'">
                  <label class="btn btn-outline-success" for="contract_type-free">
                    Бесплатно
                  </label>
<!--                  <input type="radio"-->
<!--                         class="btn-check"-->
<!--                         name="options-outlined-contract_type"-->
<!--                         id="contract_type-fee"-->
<!--                         @input="form.contract_type = 'fee'"-->
<!--                         autocomplete="off"-->
<!--                         :checked="form.contract_type === 'fee'">-->
<!--                  <label class="btn btn-outline-success" for="contract_type-fee">-->
<!--                    Абонплата-->
<!--                  </label>-->
                  <input type="radio" class="btn-check"
                         name="options-outlined-contract_type"
                         id="contract_type-compensation"
                         @input="form.contract_type = 'compensation'"
                         autocomplete="off"
                         :checked="form.contract_type === 'compensation'">
                  <label class="btn btn-outline-success" for="contract_type-compensation">
                    Компенсация
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" v-if="['client', 'exchange'].includes(form.owner_type)">
            <div class="row">
              <h5>Данные владельца</h5>

              <div class="col-3" v-bind:class="{ 'has-error': form.errors.has('client_id') }">
                <multiselect :allow-empty="true"
                             :options="clients"
                             :searchable="true"
                             :show-labels="true"
                             id="client_id"
                             @input="() => {
                               form.client_id = client ? client.id : null;
                               form.client_name = client ? client.name : null;
                               form.client_phone = client ? client.phone.toString() : '';
                               if (client.phone_two) {
                                 form.client_phone_two = client ? client.phone_two.toString() : '';
                               }
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="ФИО"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="ФИО"
                             track-by="id"
                             v-model="client"
                             :disabled="sellerTaskID !== null">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.name }}, {{ props.option.phone }}
                  </template>
                  <!--                  <template slot="option" slot-scope="props">-->
                  <!--                    {{ props.option.name }}, <span class="text-muted">{{ props.option.phone }}</span>-->
                  <!--                  </template>-->
                </multiselect>
                <HasError :form="form" field="client_id" />
              </div>

              <div class="col-9" v-if="!sellerTaskID">
                <div class="row">
                  <div class="col-md-4 col-sm-12" v-bind:class="{ 'has-error': form.errors.has('client_name') }">
                    <input class="form-control"
                           :disabled="Boolean(form.client_id)"
                           id="client_name"
                           name="client_name"
                           placeholder="ФИО владельца"
                           title="ФИО владельца"
                           type="text"
                           v-model="form.client_name">
                    <HasError :form="form" field="client_name" />
                  </div>

                  <div class="col-md-4 col-sm-12" v-bind:class="{ 'has-error': form.errors.has('client_phone') }">
                    <vue-tel-input id="client_phone"
                                   :disabled="Boolean(form.client_id)"
                                   name="client_phone"
                                   v-bind="phone_config"
                                   v-model="form.client_phone">
                    </vue-tel-input>
                    <HasError :form="form" field="client_phone" />
                  </div>

                  <div class="col-md-4 col-sm-12" v-bind:class="{ 'has-error': form.errors.has('client_phone_two') }">
                    <vue-tel-input id="client_phone_two"
                                   :disabled="Boolean(form.client_id)"
                                   name="client_phone_two"
                                   v-bind="phone_config"
                                   v-model="form.client_phone_two">
                    </vue-tel-input>
                    <HasError :form="form" field="client_phone_two" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-12">
                <label class="font-medium font-small">
                  Местоположение
                </label>
                <div class="form-group mt-1">
                  <input type="radio" class="btn-check" name="options-outlined" id="location-showroom"
                         @input="form.location = 'showroom'" autocomplete="off" :checked="form.location === 'showroom'">
                  <label class="btn btn-outline-success" for="location-showroom">Автосалон</label>
                  <input type="radio" class="btn-check" name="options-outlined" id="location-owner"
                         @input="form.location = 'owner'" autocomplete="off" :checked="form.location === 'owner'">
                  <label class="btn btn-outline-success" for="location-owner">У владельца</label>
                </div>
              </div>

              <div class="col-lg-3 col-md-12">
                <div class="form-group mt-3" v-bind:class="{ 'has-error': form.errors.has('client_address') }">
                  <input class="form-control"
                         id="client_address"
                         name="client_address"
                         placeholder="Адрес владельца"
                         title="Адрес владельца"
                         type="text"
                         v-model="form.client_address">
                  <HasError :form="form" field="client_address" />
                </div>
              </div>

              <div class="col-lg-3 col-md-12">
                <div class="form-group mt-3" v-bind:class="{ 'has-error': form.errors.has('client_driver_license') }">
                  <input class="form-control"
                         id="client_driver_license"
                         name="client_driver_license"
                         placeholder="Удостоверение водителя / ИНН"
                         title="Удостоверение водителя / ИНН"
                         type="text"
                         v-model="form.client_driver_license">
                  <HasError :form="form" field="client_driver_license" />
                </div>
              </div>
            </div>

            <hr style="margin: 0.5rem 0;" />

          </div>

          <div class="form-group">
            <div class="row">
              <h5>Данные авто</h5>

              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('mark_id') }">
                <multiselect :allow-empty="false"
                             :options="marks"
                             :searchable="true"
                             @input="() => {
                               form.mark_id = mark ? mark.id : null;
                             }"
                             @select="getCarModels"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Марка"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Марка"
                             track-by="id"
                             v-model="mark"
                             :disabled="sellerTaskID !== null">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="mark_id" />
              </div>

              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('model_id') }">
                <multiselect :allow-empty="false"
                             :disabled="!models || models.length === 0 || sellerTaskID !== null"
                             :options="models"
                             :searchable="true"
                             @input="() => {
                               form.model_id = model ? model.id : null;
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Модель"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Модель"
                             track-by="id"
                             v-model="model">
                  <template slot="placeholder">
                    <i class="fas font-scale fa-circle-notch fa-spin text-blue" v-if="onProgressCarModels === true" />
                    Модель
                  </template>
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="model_id" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('branch_id') }">
                <multiselect :allow-empty="false"
                             :options="branches"
                             :searchable="false"
                             :show-labels="true"
                             @input="() => {
                               form.branch_id = branch ? branch.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Филиал"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Филиал"
                             track-by="id"
                             v-model="branch">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.title }}, {{ props.option.address }}
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                  </template>
                </multiselect>
                <HasError :form="form" field="branch_id" />
              </div>
              <div class="col-lg-3 col-md-6"
                   v-bind:class="{ 'has-error': form.errors.has('type_id') }">
                <multiselect :allow-empty="false"
                             :options="types"
                             :searchable="false"
                             @input="() => {
                               form.type_id = type ? type.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Тип кузова"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Тип кузова"
                             track-by="id"
                             v-model="type">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="type_id" />
              </div>
              <div class="col-lg-3 col-md-6"
                   v-bind:class="{ 'has-error': form.errors.has('origin_id') }">
                <multiselect :allow-empty="false"
                             :options="origins"
                             :searchable="false"
                             @input="() => {
                               form.origin_id = origin ? origin.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Происхождение"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Происхождение"
                             track-by="id"
                             v-model="origin">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="origin_id" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('reg_number') }">
                <label class="full-width" for="reg_number">
                  <input class="form-control"
                         id="reg_number"
                         name="reg_number"
                         placeholder="Гос. номер"
                         required
                         title="Гос. номер"
                         type="text"
                         v-model="form.reg_number">
                  <HasError :form="form" field="reg_number" />
                </label>
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('year') }">
                <label class="full-width" for="year">
                  <input :max="2024"
                         :min="1900"
                         class="form-control"
                         id="year"
                         name="year"
                         placeholder="Год выпуска"
                         required
                         title="Год выпуска"
                         type="number"
                         v-model="form.year">
                  <HasError :form="form" field="year" />
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('vin_code') }">
                <label class="full-width" for="vin_code">
                  <input :max="17"
                         class="form-control"
                         id="vin_code"
                         name="vin_code"
                         placeholder="VIN-код"
                         required
                         title="VIN-код"
                         type="text"
                         v-model="form.vin_code">
                  <HasError :form="form" field="vin_code" />
                </label>
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('gearbox_id') }">
                <multiselect :allow-empty="false"
                             :options="gearboxes"
                             :searchable="false"
                             @input="() => {
                               form.gearbox_id = gearbox ? gearbox.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="КПП"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="КПП"
                             track-by="id"
                             v-model="gearbox">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="gearbox_id" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('fuel_id') }">
                <multiselect :allow-empty="false"
                             :options="fuels"
                             :searchable="false"
                             @input="() => {
                               form.fuel_id = fuel ? fuel.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Топливо"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Топливо"
                             track-by="id"
                             v-model="fuel">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="fuel_id" />
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('color_id') }">
                <multiselect :allow-empty="false"
                             :options="colors"
                             :searchable="false"
                             @input="() => {
                               form.color_id = color ? color.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Цвет"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Цвет"
                             track-by="id"
                             v-model="color">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="color_id" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('engine') }">
                <label class="full-width" for="engine">
                  <input :min="0.1"
                         class="form-control"
                         id="engine"
                         name="engine"
                         placeholder="Объем двигателя"
                         required
                         step=".1"
                         title="Объем двигателя"
                         type="number"
                         v-model="form.engine">
                  <HasError :form="form" field="engine" />
                </label>
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('capacity') }">
                <label class="full-width" for="capacity">
                  <input :max="30"
                         :min="1"
                         class="form-control"
                         id="capacity"
                         name="capacity"
                         placeholder="Кол-во мест"
                         required
                         title="Кол-во мест"
                         type="number"
                         v-model="form.capacity">
                  <HasError :form="form" field="capacity" />
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('race') }">
                <label class="full-width" for="race">
                  <input :max="9999999"
                         :min="1"
                         class="form-control"
                         id="race"
                         name="race"
                         placeholder="Пробег"
                         required
                         title="Пробег"
                         type="number"
                         v-model="form.race">
                  <HasError :form="form" field="race" />
                </label>
              </div>
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('transmission_id') }">
                <multiselect :allow-empty="false"
                             :options="transmissions"
                             :searchable="false"
                             @input="() => {
                               form.transmission_id = transmission ? transmission.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Привод"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Привод"
                             track-by="id"
                             v-model="transmission">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="transmission_id" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('price') }">
                <label class="full-width" for="price">
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         id="price"
                         name="price"
                         placeholder="Стоимость"
                         required
                         title="Стоимость"
                         type="number"
                         v-model="form.price">
                  <HasError :form="form" field="price" />
                </label>
              </div>
              <div class="col-6">
                <div class="form-control">
                  <input class="pointer"
                         id="is_top_price"
                         style="transform: scale(1.2);"
                         type="checkbox"
                         v-model="form.is_top_price">
                  <label for="is_top_price" class="margin-l-5 padding-l-10">Суперцена</label>

                  <br class="d-xxl-none d-xl-none d-lg-none d-md-none" />

                  <input class="pointer"
                         id="is_checked"
                         style="transform: scale(1.2);"
                         type="checkbox"
                         v-model="form.is_checked">
                  <label for="is_checked" class="margin-l-5 padding-l-10">Проверен на СТО</label>

                  <br class="d-xxl-none d-xl-none d-lg-none d-md-none" />

                  <template v-if="[1, 7, 8].includes($store.getters['auth/authUser'].role.id)">
                    <input class="pointer"
                           id="is_our"
                           style="transform: scale(1.2);"
                           type="checkbox"
                           v-model="form.is_our">
                    <label for="is_our" class="margin-l-5 padding-l-10">Выкупленное авто</label>

                    <br class="d-xxl-none d-xl-none d-lg-none d-md-none" />
                  </template>

                  <input class="pointer"
                         id="is_new"
                         style="transform: scale(1.2);"
                         type="checkbox"
                         v-model="form.is_new">
                  <label for="is_new" class="margin-l-5 padding-l-10">Новый</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" v-if="form.owner_type === 'client'">
            <div class="row">
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('price_for_owner') }">
                <label class="full-width" for="price_for_owner">
                  <input :max="999999999"
                         class="form-control"
                         id="price_for_owner"
                         name="price_for_owner"
                         placeholder="Стоимость в руки"
                         title="Стоимость в руки"
                         type="number"
                         v-model="form.price_for_owner">
                  <HasError :form="form" field="price_for_owner" />
                </label>
              </div>

              <div class="col-6"></div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6" v-if="form.owner_type === 'client'"
                   v-bind:class="{ 'has-error': form.errors.has('credit_percent') }">
                <label class="full-width" for="credit_percent">
                  <small>Процент компании</small>
                  <input :max="100"
                         :min="1"
                         class="form-control"
                         id="credit_percent"
                         name="credit_percent"
                         placeholder="Процент компании"
                         required
                         title="Стоимость"
                         type="number"
                         v-model="form.credit_percent">
                  <HasError :form="form" field="credit_percent" />
                </label>
              </div>
              <div v-else class="col-6" v-bind:class="{ 'has-error': form.errors.has('minimum_price') }">
                <label class="full-width" for="minimum_price">
                  <small>Минимальная стоимость</small>
                  <input :max="999999999"
                         class="form-control"
                         id="minimum_price"
                         name="minimum_price"
                         placeholder="Минимальная стоимость"
                         title="Минимальная стоимость"
                         type="number"
                         v-model="form.minimum_price">
                  <HasError :form="form" field="minimum_price" />
                </label>
              </div>

              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('tech_passport') }">
                <label class="full-width" for="tech_passport">
                  <input class="form-control mt-4"
                         id="tech_passport"
                         name="tech_passport"
                         placeholder="Тех. паспорт"
                         title="Тех. паспорт"
                         type="text"
                         v-model="form.tech_passport">
                  <HasError :form="form" field="tech_passport" />
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('damage_link') }">
                <label class="full-width" for="damage_link">
                  <input class="form-control"
                         id="damage_link"
                         name="damage_link"
                         placeholder="Ссылка на фото повреждений"
                         title="Ссылка на фото повреждений"
                         type="text"
                         v-model="form.damage_link">
                  <HasError :form="form" field="damage_link" />
                </label>
              </div>
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('old_ads_link') }">
                <label class="full-width" for="old_ads_link">
                  <input class="form-control"
                         id="old_ads_link"
                         name="old_ads_link"
                         placeholder="Ссылка на старую рекламу"
                         title="Ссылка на старую рекламу"
                         type="text"
                         v-model="form.old_ads_link">
                  <HasError :form="form" field="old_ads_link" />
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('autoria_id') }">
                <label class="full-width" for="autoria_id">
                  <input class="form-control"
                         id="autoria_id"
                         name="autoria_id"
                         placeholder="AutoRIA ID"
                         title="AutoRIA ID"
                         type="number"
                         v-model="form.autoria_id">
                  <HasError :form="form" field="autoria_id" />
                </label>
              </div>
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('olx_id') }">
                <label class="full-width" for="olx_id">
                  <input class="form-control"
                         id="olx_id"
                         name="olx_id"
                         placeholder="OLX ID"
                         title="OLX ID"
                         type="number"
                         v-model="form.olx_id">
                  <HasError :form="form" field="olx_id" />
                </label>
              </div>
            </div>
          </div>

          <hr class="mt-4" />

          <div class="form-group">
            <div class="row">
              <h5>Данные осмотра</h5>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('wheels_condition') }">
                <h5 class="text-left font-medium font-scale-1">Состояние колес</h5>

                <div class="form-group">
                  <div class="row">
                    <div class="col-6"
                         v-bind:class="{ 'has-error': form.errors.has('wheels_condition.tire_condition') }">
                      <label class="font-medium font-small">
                        Резина
                      </label>
                      <select class="form-select"
                              v-model="form.wheels_condition.tire_condition"
                              aria-label="Выберите состояние">
                        <option :value="null" selected>Выберите состояние</option>
                        <option v-for="(name, type) in condition_types" :value="name">
                          {{ name }}
                        </option>
                      </select>
                      <HasError :form="form" field="wheels_condition.tire_condition" />
                    </div>
                    <div class="col-6"
                         v-bind:class="{ 'has-error': form.errors.has('wheels_condition.tire_condition_comment') }">
                      <label class="font-medium font-small">
                        Резина (комментарий)
                      </label>
                      <input class="form-control"
                             id="wheels_condition_tire_condition_comment"
                             name="wheels_condition_tire_condition_comment"
                             placeholder="Комментарий"
                             title="Комментарий"
                             type="text"
                             v-model="form.wheels_condition.tire_condition_comment">
                      <HasError :form="form" field="wheels_condition.tire_condition_comment" />
                    </div>
                    <div class="col-6"
                         v-bind:class="{ 'has-error': form.errors.has('wheels_condition.rim_condition') }">
                      <label class="font-medium font-small">
                        Диски
                      </label>
                      <select class="form-select"
                              v-model="form.wheels_condition.rim_condition"
                              aria-label="Выберите состояние">
                        <option :value="null" selected>Выберите состояние</option>
                        <option v-for="(name, type) in condition_types" :value="name">
                          {{ name }}
                        </option>
                      </select>
                      <HasError :form="form" field="wheels_condition.rim_condition" />
                    </div>
                    <div class="col-6"
                         v-bind:class="{ 'has-error': form.errors.has('wheels_condition.rim_condition') }">
                      <label class="font-medium font-small">
                        Диски (комментарий)
                      </label>
                      <input class="form-control"
                             id="wheels_condition_rim_condition_comment"
                             name="wheels_condition_rim_condition_comment"
                             placeholder="Комментарий"
                             title="Комментарий"
                             type="text"
                             v-model="form.wheels_condition.rim_condition_comment">
                      <HasError :form="form" field="wheels_condition.rim_condition" />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12"
                         v-bind:class="{ 'has-error': form.errors.has('additional_marking.tire_second_set') }">
                      <input class="pointer"
                             id="additional_marking_tire_second_set"
                             style="transform: scale(1.2);"
                             type="checkbox"
                             v-model="form.additional_marking.tire_second_set">
                      <label for="additional_marking_tire_second_set" class="margin-l-5">Второй комплект</label>
                    </div>
                  </div>
                </div>
                <HasError :form="form" field="wheels_condition" />
              </div>
              <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('computer_diagnostic') }">
                <h5 class="text-left font-medium font-scale-1">Компьютерная диагностика</h5>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12"
                         v-bind:class="{ 'has-error': form.errors.has('computer_diagnostic.has_errors') }">
                      <input class="pointer"
                             id="computer_diagnostic_has_errors"
                             style="transform: scale(1.2);"
                             type="checkbox"
                             v-model="form.computer_diagnostic.has_errors">
                      <label for="computer_diagnostic_has_errors" class="margin-l-5">Есть ошибки</label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-12"
                         v-bind:class="{ 'has-error': form.errors.has('computer_diagnostic.comment') }">
                <textarea class="form-control"
                          id="computer_diagnostic_comment"
                          name="computer_diagnostic_comment"
                          type="text"
                          placeholder="Комментарий"
                          v-model="form.computer_diagnostic.comment" rows="3"></textarea>
                      <HasError :form="form" field="computer_diagnostic.comment" />
                    </div>
                  </div>
                </div>
                <HasError :form="form" field="computer_diagnostic" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('interior_condition') }">
                <h5 class="text-left font-medium font-scale-1">Состояние салона</h5>

                <div class="row">

                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.wheel_and_gearbox') }">
                    <label class="font-medium font-small">
                      Руль и КПП
                    </label>
                    <label class="full-width" for="interior_condition_wheel_and_gearbox">
                      <select class="form-select"
                              v-model="form.interior_condition.wheel_and_gearbox"
                              aria-label="Выберите состояние">
                        <option :value="null" selected>Выберите состояние</option>
                        <option v-for="(name, type) in condition_types" :value="name">
                          {{ name }}
                        </option>
                      </select>
                      <HasError :form="form" field="interior_condition.wheel_and_gearbox" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.wheel_and_gearbox_comment') }">
                    <label class="font-medium font-small">
                      Руль и КПП комментарий
                    </label>
                    <label class="full-width" for="interior_condition_wheel_and_gearbox_comment">
                      <input class="form-control"
                             id="interior_condition_wheel_and_gearbox_comment"
                             name="interior_condition_wheel_and_gearbox_comment"
                             placeholder="Комментарий"
                             title="Комментарий"
                             type="text"
                             v-model="form.interior_condition.wheel_and_gearbox_comment">
                      <HasError :form="form" field="interior_condition.wheel_and_gearbox_comment" />
                    </label>
                  </div>
                  <div class="col-xl-12 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.equipment') }">
                    <label class="font-medium font-small">
                      Оборудование
                    </label>
                    <label class="full-width" for="interior_condition_equipment">
                      <input class="form-control"
                             id="interior_condition_equipment"
                             name="interior_condition_equipment"
                             placeholder="Оборудование"
                             title="Оборудование"
                             type="text"
                             v-model="form.interior_condition.equipment">
                      <HasError :form="form" field="interior_condition.equipment" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.seats') }">
                    <label class="font-medium font-small">
                      Сиденья
                    </label>
                    <label class="full-width" for="interior_condition_seats">
                      <select class="form-select"
                              v-model="form.interior_condition.seats"
                              aria-label="Выберите состояние">
                        <option :value="null" selected>Выберите состояние</option>
                        <option v-for="(name, type) in condition_types" :value="name">
                          {{ name }}
                        </option>
                      </select>
                      <HasError :form="form" field="interior_condition.seats" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.seats_comment') }">
                    <label class="font-medium font-small">
                      Сиденья (комментарий)
                    </label>
                    <label class="full-width" for="interior_condition_seats_comment">
                      <input class="form-control"
                             id="interior_condition_seats_comment"
                             name="interior_condition_seats_comment"
                             placeholder="Комментарий"
                             title="Комментарий"
                             type="text"
                             v-model="form.interior_condition.seats_comment">
                      <HasError :form="form" field="interior_condition.seats_comment" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.floor') }">
                    <label class="font-medium font-small">
                      Пол
                    </label>
                    <label class="full-width" for="interior_condition_floor">
                      <select class="form-select"
                              v-model="form.interior_condition.floor"
                              aria-label="Выберите состояние">
                        <option :value="null" selected>Выберите состояние</option>
                        <option v-for="(name, type) in condition_types" :value="name">
                          {{ name }}
                        </option>
                      </select>
                      <HasError :form="form" field="interior_condition.floor" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.floor_comment') }">
                    <label class="font-medium font-small">
                      Пол (комментарий)
                    </label>
                    <label class="full-width" for="interior_condition_floor_comment">
                      <input class="form-control"
                             id="interior_condition_floor_comment"
                             name="interior_condition_floor_comment"
                             placeholder="Комментарий"
                             title="Комментарий"
                             type="text"
                             v-model="form.interior_condition.floor_comment">
                      <HasError :form="form" field="interior_condition.floor_comment" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.ceiling') }">
                    <label class="font-medium font-small">
                      Потолок
                    </label>
                    <label class="full-width" for="interior_condition_ceiling">
                      <select class="form-select"
                              v-model="form.interior_condition.ceiling"
                              aria-label="Выберите состояние">
                        <option :value="null" selected>Выберите состояние</option>
                        <option v-for="(name, type) in condition_types" :value="name">
                          {{ name }}
                        </option>
                      </select>
                      <HasError :form="form" field="interior_condition.ceiling" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.ceiling_comment') }">
                    <label class="font-medium font-small">
                      Потолок (комментарий)
                    </label>
                    <label class="full-width" for="interior_condition_ceiling_comment">
                      <input class="form-control"
                             id="interior_condition_ceiling_comment"
                             name="interior_condition_ceiling_comment"
                             placeholder="Комментарий"
                             title="Комментарий"
                             type="text"
                             v-model="form.interior_condition.ceiling_comment">
                      <HasError :form="form" field="interior_condition.ceiling_comment" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.trunk') }">
                    <label class="font-medium font-small">
                      Багажник
                    </label>
                    <label class="full-width" for="interior_condition_trunk">
                      <select class="form-select"
                              v-model="form.interior_condition.trunk"
                              aria-label="Выберите состояние">
                        <option :value="null" selected>Выберите состояние</option>
                        <option v-for="(name, type) in condition_types" :value="name">
                          {{ name }}
                        </option>
                      </select>
                      <HasError :form="form" field="interior_condition.trunk" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('interior_condition.trunk_comment') }">
                    <label class="font-medium font-small">
                      Багажник (комментарий)
                    </label>
                    <label class="full-width" for="interior_condition_trunk_comment">
                      <input class="form-control"
                             id="interior_condition_trunk_comment"
                             name="interior_condition_trunk_comment"
                             placeholder="Комментарий"
                             title="Комментарий"
                             type="text"
                             v-model="form.interior_condition.trunk_comment">
                      <HasError :form="form" field="interior_condition.trunk_comment" />
                    </label>
                  </div>
                </div>

                <HasError :form="form" field="interior_condition" />
              </div>

              <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('additional_marking') }">
                <h5 class="text-left font-medium font-scale-1">Дополнительные отметки</h5>

                <div class="row">

                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.sales_market') }">
                    <label class="font-medium font-small">
                      Рынок сбыта
                    </label>
                    <label class="full-width" for="additional_marking_sales_market">
                      <input class="form-control"
                             id="additional_marking_sales_market"
                             name="additional_marking_sales_market"
                             placeholder="Рынок сбыта"
                             title="Рынок сбыта"
                             type="text"
                             v-model="form.additional_marking.sales_market">
                      <HasError :form="form" field="additional_marking.sales_market" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.keys_count') }">
                    <label class="font-medium font-small">
                      Количество ключей
                    </label>
                    <label class="full-width" for="additional_marking_keys_count">
                      <input class="form-control"
                             id="additional_marking_keys_count"
                             name="additional_marking_keys_count"
                             placeholder="Количество ключей"
                             title="Количество ключей"
                             type="number"
                             v-model="form.additional_marking.keys_count">
                      <HasError :form="form" field="additional_marking.keys_count" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.owners_count') }">
                    <label class="font-medium font-small">
                      Количество владельцев
                    </label>
                    <label class="full-width" for="additional_marking_owners_count">
                      <input class="form-control"
                             id="additional_marking_owners_count"
                             name="additional_marking_owners_count"
                             placeholder="Количество владельцев"
                             title="Количество владельцев"
                             type="number"
                             v-model="form.additional_marking.owners_count">
                      <HasError :form="form" field="additional_marking.owners_count" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.has_service_book') }">
                    <div class="mt-4 mb-4">
                      <input class="pointer"
                             id="additional_marking_has_service_book"
                             style="transform: scale(1.2);"
                             type="checkbox"
                             v-model="form.additional_marking.has_service_book">
                      <label for="additional_marking_has_service_book" class="margin-l-5">Сервисная книга</label>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.last_tech_inspect_race') }">
                    <label class="font-medium font-small">
                      Последний техосмотр, тыс. км.
                    </label>
                    <label class="full-width" for="additional_marking_last_tech_inspect_race">
                      <input class="form-control"
                             id="additional_marking_last_tech_inspect_race"
                             name="additional_marking_last_tech_inspect_race"
                             placeholder="Последний техосмотр"
                             title="Последний техосмотр"
                             type="number"
                             v-model="form.additional_marking.last_tech_inspect_race">
                      <HasError :form="form" field="additional_marking.last_tech_inspect_race" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.last_tech_inspect_changes') }">
                    <label class="font-medium font-small">
                      Замена
                    </label>
                    <label class="full-width" for="additional_marking_last_tech_inspect_changes">
                      <input class="form-control"
                             id="additional_marking_last_tech_inspect_changes"
                             name="additional_marking_last_tech_inspect_changes"
                             placeholder="Замена"
                             title="Замена"
                             type="text"
                             v-model="form.additional_marking.last_tech_inspect_changes">
                      <HasError :form="form" field="additional_marking.last_tech_inspect_changes" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.big_tech_inspect') }">
                    <label class="font-medium font-small">
                      Большой техосмотр
                    </label>
                    <label class="full-width" for="additional_marking_big_tech_inspect">
                      <input class="form-control"
                             id="additional_marking_big_tech_inspect"
                             name="additional_marking_big_tech_inspect"
                             placeholder="Большой техосмотр"
                             title="Большой техосмотр"
                             type="text"
                             v-model="form.additional_marking.big_tech_inspect">
                      <HasError :form="form" field="additional_marking.big_tech_inspect" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.big_tech_inspect_changes') }">
                    <label class="font-medium font-small">
                      Замена
                    </label>
                    <label class="full-width" for="additional_marking_big_tech_inspect_changes">
                      <input class="form-control"
                             id="additional_marking_big_tech_inspect_changes"
                             name="additional_marking_big_tech_inspect_changes"
                             placeholder="Замена"
                             title="Замена"
                             type="text"
                             v-model="form.additional_marking.big_tech_inspect_changes">
                      <HasError :form="form" field="additional_marking.big_tech_inspect_changes" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.need_changes') }">
                    <label class="font-medium font-small">
                      Требует замены и рекомендации
                    </label>
                    <label class="full-width" for="additional_marking_need_changes">
                  <textarea class="form-control"
                            id="additional_marking_need_changes"
                            rows="2"
                            v-model="form.additional_marking.need_changes">
                  </textarea>
                      <HasError :form="form" field="additional_marking.need_changes" />
                    </label>
                  </div>
                  <div class="col-xl-6 col-md-12"
                       v-bind:class="{ 'has-error': form.errors.has('additional_marking.notes') }">
                    <label class="font-medium font-small">
                      Примечания
                    </label>
                    <label class="full-width" for="additional_marking_notes">
                  <textarea class="form-control"
                            id="additional_marking_notes"
                            rows="3"
                            v-model="form.additional_marking.notes">
                  </textarea>
                      <HasError :form="form" field="additional_marking.notes" />
                    </label>
                  </div>
                </div>

                <HasError :form="form" field="additional_marking" />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-12 text-center" v-bind:class="{ 'has-error': form.errors.has('paintwork_details') }">
                <h5 class="text-left font-medium font-scale-1">Состояние ЛКП</h5>

                <div class="row">
                  <div class="col-xl-6 col-md-12">
                    <div class="image-box">
                      <img src="../../assets/img/inspection_scheme/car_scheme_front.png" class="img-fluid" alt="">
                      <button class="btn btn-sm" id="front_bumper" type="button"
                              :class="form.paintwork_details['front']['bumper'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('front', 'bumper')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="front_hood" type="button"
                              :class="form.paintwork_details['front']['hood'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('front', 'hood')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12">
                    <div class="image-box">
                      <img src="../../assets/img/inspection_scheme/car_scheme_side_left.png" class="img-fluid" alt=""
                           style="width: 90%;">
                      <button class="btn btn-sm" id="side_left_front_wing" type="button"
                              :class="form.paintwork_details['side_left']['front_wing'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('side_left', 'front_wing')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="side_left_front_door" type="button"
                              :class="form.paintwork_details['side_left']['front_door'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('side_left', 'front_door')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="side_left_back_door" type="button"
                              :class="form.paintwork_details['side_left']['back_door'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('side_left', 'back_door')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="side_left_back_wing" type="button"
                              :class="form.paintwork_details['side_left']['back_wing'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('side_left', 'back_wing')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12">
                    <div class="image-box">
                      <img src="../../assets/img/inspection_scheme/car_scheme_side_right.png" class="img-fluid" alt=""
                           style="width: 90%;">
                      <button class="btn btn-sm" id="side_right_front_wing" type="button"
                              :class="form.paintwork_details['side_right']['front_wing'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('side_right', 'front_wing')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="side_right_front_door" type="button"
                              :class="form.paintwork_details['side_right']['front_door'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('side_right', 'front_door')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="side_right_back_door" type="button"
                              :class="form.paintwork_details['side_right']['back_door'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('side_right', 'back_door')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="side_right_back_wing" type="button"
                              :class="form.paintwork_details['side_right']['back_wing'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('side_right', 'back_wing')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12">
                    <div class="image-box">
                      <img src="../../assets/img/inspection_scheme/car_scheme_back.png" class="img-fluid" alt="">
                      <button class="btn btn-sm" id="back_bumper" type="button"
                              :class="form.paintwork_details['back']['bumper'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('back', 'bumper')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="back_tailgate_bottom" type="button"
                              :class="form.paintwork_details['back']['tailgate_bottom'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('back', 'tailgate_bottom')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-6 col-md-12">
                    <div class="image-box">
                      <img src="../../assets/img/inspection_scheme/car_scheme_roof.png" class="img-fluid" alt=""
                           style="width: 90%;">
                      <button class="btn btn-sm" id="roof_tailgate_top" type="button"
                              :class="form.paintwork_details['roof']['tailgate_top'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('roof', 'tailgate_top')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                      <button class="btn btn-sm" id="roof_main" type="button"
                              :class="form.paintwork_details['roof']['main'].condition_type ? 'btn-warning' : 'btn-primary'"
                              v-on:click="showPaintworkDetailsModal('roof', 'main')">
                        <i class="fas fa-map-marker"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <modal :adaptive="true"
                       :clickToClose="true"
                       :draggable="false"
                       name="paintwork_details_modal"
                       :resizable="false"
                       :scrollable="false"
                       :shiftX="0.5"
                       :shiftY="0.5"
                       :height="'auto'"
                       overlayTransition="fade"
                       transition="fade">
                  <div class="box box-primary box-modal mb-0"
                       v-if="paintwork_modal_data.section && paintwork_modal_data.detail_type">
                    <div class="box-header with-border mb-3">
                      <h4>
                        {{ paintwork_modal_data.section.toUpperCase() }}
                        {{ paintwork_modal_data.detail_type.toUpperCase() }}
                      </h4>
                    </div>

                    <div class="box-body">
                      <input class="form-control hidden"
                             style="display: none;"
                             name="paintwork_modal_data_section"
                             type="text"
                             v-model="paintwork_modal_data.section">
                      <input class="form-control hidden"
                             style="display: none;"
                             name="paintwork_modal_data_detail_type"
                             type="text"
                             v-model="paintwork_modal_data.detail_type">

                      <div class="form-group">
                        <div class="row">
                          <div class="col-6">
                            <select class="form-select"
                                    v-model="paintwork_modal_data.condition_type"
                                    aria-label="Выберите состояние">
                              <option :value="null" selected>Выберите состояние</option>
                              <option v-for="(name, type) in paintwork_condition_types" :value="name">
                                {{ name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="form-group"
                           v-if="paintwork_modal_data.condition_type === paintwork_condition_types.painted">
                        <div class="row">
                          <div class="col-6">
                            <label for="paintwork_modal_data_from_mcr">
                              От, мкр.
                            </label>
                            <input class="form-control"
                                   id="paintwork_modal_data_from_mcr"
                                   name="paintwork_modal_data_from_mcr"
                                   placeholder="От, мкр."
                                   type="number"
                                   min="0"
                                   v-model="paintwork_modal_data.from_mcr">
                          </div>
                          <div class="col-6">
                            <label for="paintwork_modal_data_to_mcr">
                              До, мкр.
                            </label>
                            <input class="form-control"
                                   id="paintwork_modal_data_to_mcr"
                                   name="paintwork_modal_data_to_mcr"
                                   placeholder="До, мкр."
                                   type="number"
                                   min="0"
                                   v-model="paintwork_modal_data.to_mcr">
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="row">
                          <div class="col-12">
                            <label for="paintwork_modal_data_additional_mark">
                              <input class="form-control"
                                     id="paintwork_modal_data_additional_mark"
                                     name="paintwork_modal_data_additional_mark"
                                     placeholder="Примечание"
                                     type="text"
                                     v-model="paintwork_modal_data.additional_mark">
                            </label>
                          </div>
                        </div>
                      </div>

                      <button class="btn btn-success btn-lg pull-right margin-l-10" type="button"
                              v-on:click="addPaintworkDetail">
                        <i class="fa fa-save"></i>
                      </button>

                      <button class="btn btn-default btn-lg pull-right" title="Отменить и закрыть окно" type="button"
                              v-on:click="hidePaintworkDetailsModal">
                        <i class="far fa-window-close"></i>
                      </button>
                    </div>
                  </div>
                </modal>

                <HasError :form="form" field="paintwork_details" />
              </div>
            </div>
          </div>

          <hr class="mt-4" />

          <div class="form-group">
            <div class="row">
              <h5>Фото/видео повреждений/документов</h5>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <vue-dropzone v-if="onProgressLoadFile === false"
                            id="dropzone-files"
                            ref="car_tmp_files"
                            :duplicateCheck="true"
                            :options="dropzoneOptionsFiles"
                            v-on:vdropzone-file-added="sendFile"
                            v-on:vdropzone-success="getFiles"
                            v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
              </vue-dropzone>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-12" v-for="item in car_tmp_files">
              <div class="image-files-box">
                <img
                  v-if="dropzoneOptionsFiles.img_extensions.includes(item.file_url.slice(item.file_url.lastIndexOf('.')))"
                  :src="item.storage_app_url + item.file_url"
                  class="img-fluid p-3" :alt="item.description">
                <div v-else class="full-height p-3">
                  <div class="p-2 bg-gray-light">
                    <h5 class="mb-3 font-scale-2" style="word-break: break-all;">
                      {{ item.file_url.slice(item.file_url.lastIndexOf("/")).substring(1) }}

                      <br />

                      <small class="font-small-xs">
                        {{ item.created_date }}
                      </small>

                      <br />
                      <br />
                    </h5>
                  </div>
                </div>
                <button @click="removeFile(item.id)"
                        class="btn" title="Удалить файл" type="button">
                  <i class="fa fa-trash"></i>
                </button>
                <a :href="item.storage_app_url + item.file_url"
                   target="_blank" class="btn" title="Открыть"
                   style="left: 82%; right: 0; opacity: 0.85;">
                  <i class="fas fa-external-link-square-alt"></i>
                </a>
              </div>
            </div>
            <div v-if="car_tmp_files.length === 0" class="text-center text-muted mt-5 mb-5">
              Нет элементов для отображения
            </div>
          </div>

          <br />

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>
        </form>
        <loading-processing v-else></loading-processing>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Form from "vform";
import Multiselect from "vue-multiselect";
import { VueTelInput } from "vue-tel-input";
import phone_config from "./../../assets/data/vue-tel-input-config.json";
import BranchService from "../../services/BranchService";
import VModal from "vue-js-modal";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import dropzone_config from "./../../assets/data/dropzone-config.json";

Form.axios = API.apiClient;

Vue.use(VModal);

export default {
  name: "create",

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
    VueTelInput,
    vueDropzone: vue2Dropzone,
  },

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  props: {
    currentCarData: {
      type: [Object, null],
      required: false,
      default: null,
    },
    currentClientItem: {
      type: [Object, null],
      required: false,
      default: null,
    },
    sellerTaskID: {
      type: [Number, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    page_title: "Добавить авто",

    form: new Form({
      mark_id: null,
      model_id: null,
      branch_id: null,
      owner_type: "client",
      location: "showroom",
      type_id: null,
      origin_id: null,
      year: null,
      price: null,
      price_for_owner: null,
      minimum_price: null,
      contract_type: "free",
      autoria_id: null,
      olx_id: null,
      credit_percent: 4,
      is_top_price: false,
      is_checked: false,
      is_new: false,
      is_our: false,
      reg_number: null,
      vin_code: null,
      gearbox_id: null,
      fuel_id: null,
      color_id: null,
      transmission_id: null,
      engine: null,
      capacity: null,
      race: null,
      tech_passport: null,
      damage_link: null,
      old_ads_link: null,

      client_id: null,
      client_name: null,
      client_phone: null,
      client_phone_two: null,
      client_address: null,
      client_driver_license: null,

      task_seller_id: null,

      wheels_condition: {
        tire_condition: null,
        tire_condition_comment: null,
        rim_condition: null,
        rim_condition_comment: null,
      },
      computer_diagnostic: {
        has_errors: false,
        comment: null,
      },
      interior_condition: {
        wheel_and_gearbox: null,
        wheel_and_gearbox_comment: null,
        equipment: null,
        seats: null,
        seats_comment: null,
        floor: null,
        floor_comment: null,
        ceiling: null,
        ceiling_comment: null,
        trunk: null,
        trunk_comment: null,
      },
      additional_marking: {
        tire_second_set: false,
        sales_market: null,
        keys_count: null,
        owners_count: null,
        has_service_book: false,
        last_tech_inspect_race: null,
        last_tech_inspect_changes: null,
        big_tech_inspect: null,
        big_tech_inspect_changes: null,
        need_changes: null,
        notes: null,
        wheels_condition: null,
        computer_diagnostic: null,
      },
      paintwork_details: {
        front: {
          bumper: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          hood: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
        side_left: {
          front_wing: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          front_door: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          back_door: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          back_wing: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
        side_right: {
          front_wing: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          front_door: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          back_door: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          back_wing: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
        back: {
          bumper: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          tailgate_bottom: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
        roof: {
          tailgate_top: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
          main: {
            condition_type: null,
            from_mcr: null,
            to_mcr: null,
            additional_mark: null,
          },
        },
      },
      car_tmp_id: Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000,
    }),

    marks: [],
    mark: null,
    models: [],
    model: null,
    branches: [],
    branch: null,
    types: [],
    type: null,
    origins: [],
    origin: null,
    gearboxes: [],
    gearbox: null,
    fuels: [],
    fuel: null,
    colors: [],
    color: null,
    transmissions: [],
    transmission: null,

    clients: [],
    client: null,

    phone_config: phone_config,

    onProgress: true,
    onProgressCarModels: false,
    onProgressLoadFile: false,

    paintwork_modal_data: {
      condition_type: null,
      from_mcr: null,
      to_mcr: null,
      additional_mark: null,
      section: null,
      detail_type: null,
    },

    condition_types: {
      new: "Нове",
      good: "Гарне",
      medium: "Норм",
      poor: "Потребує ремонту",
    },

    paintwork_condition_types: {
      replace: "Заміна",
      painted: "Фарбовано",
      replace_in_color: "Заміна у колір",
    },

    car_tmp_files: [],

    dropzoneOptionsFiles: dropzone_config,
  }),

  async created() {
    try {
      const [
        marks_response,
        types_response,
        origins_response,
        gearboxes_response,
        fuels_response,
        colors_response,
        transmissions_response,
        clients_response,
      ] = await axios.all([
        API.apiClient.get("/cars/marks", {
          params: {
            is_paginated: 0,
            sorting: "asc",
            sort_column: "name",
          },
        }),
        API.apiClient.get("/cars/types"),
        API.apiClient.get("/cars/origins"),
        API.apiClient.get("/cars/gearboxes"),
        API.apiClient.get("/cars/fuels"),
        API.apiClient.get("/cars/colors"),
        API.apiClient.get("/cars/transmissions"),
        API.apiClient.get("/clients", {
          params: {
            "is_list_load": 1,
          },
        }),
      ]);

      this.marks = marks_response.data;
      this.branches = await BranchService.getItems();
      this.types = types_response.data;
      this.origins = origins_response.data;
      this.gearboxes = gearboxes_response.data;
      this.fuels = fuels_response.data;
      this.colors = colors_response.data;
      this.transmissions = transmissions_response.data;

      this.clients = clients_response.data;

      this.dropzoneOptionsFiles.url = "/cars/tmp_files/load";

      if (this.currentCarData) {
        this.mark = this.currentCarData.mark;
        this.form.mark_id = this.currentCarData.mark.id;
        this.model = this.currentCarData.model;
        this.form.model_id = this.currentCarData.model.id;
        this.form.year = this.currentCarData.year;

        this.form.race = this.currentCarData.race || null;
        this.form.engine = this.currentCarData.engine || null;

        if (this.currentCarData.color) {
          this.color = this.currentCarData.color;
          this.form.color_id = this.currentCarData.color.id;
        }
        if (this.currentCarData.gearbox) {
          this.gearbox = this.currentCarData.gearbox;
          this.form.gearbox_id = this.currentCarData.gearbox.id;
        }
        if (this.currentCarData.transmission) {
          this.transmission = this.currentCarData.transmission;
          this.form.transmission_id = this.currentCarData.transmission.id;
        }

        this.form.price_for_owner = this.currentCarData.price || null;
      }
      if (this.currentClientItem) {
        this.client = this.currentClientItem;
        this.form.client_id = this.currentClientItem.id;
      }
      if (this.sellerTaskID) {
        this.form.task_seller_id = this.sellerTaskID;
      }
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function() {
      return this.form.mark_id && this.form.model_id && this.form.branch_id && this.form.type_id && this.form.origin_id &&
        this.form.reg_number && this.form.year && this.form.vin_code && this.form.gearbox_id &&
        this.form.fuel_id && this.form.color_id && this.form.transmission_id && this.form.engine &&
        this.form.capacity && this.form.race && this.form.wheels_condition && this.form.computer_diagnostic &&
        this.form.interior_condition && this.form.additional_marking && this.form.paintwork_details;
    },
  },

  methods: {
    async getCarModels(mark) {
      try {
        this.onProgressCarModels = true;

        this.form.model_id = null;
        this.models = [];
        this.model = null;

        const models_response = await API.apiClient.get(
          "/cars/mark/" + mark.id + "/models",
          {
            params: {
              is_paginated: 0,
              sorting: "asc",
              sort_column: "name",
            },
          },
        );

        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressCarModels = false;
    },

    async showPaintworkDetailsModal(section, detail_type) {
      if (this.form.paintwork_details[section][detail_type]) {
        this.paintwork_modal_data.section = section;
        this.paintwork_modal_data.detail_type = detail_type;

        this.paintwork_modal_data.condition_type = this.form.paintwork_details[section][detail_type].condition_type;
        this.paintwork_modal_data.from_mcr = this.form.paintwork_details[section][detail_type].from_mcr;
        this.paintwork_modal_data.to_mcr = this.form.paintwork_details[section][detail_type].to_mcr;
        this.paintwork_modal_data.additional_mark = this.form.paintwork_details[section][detail_type].additional_mark;

        this.$modal.show("paintwork_details_modal");
      }
    },
    async hidePaintworkDetailsModal() {
      this.paintwork_modal_data.section = null;
      this.paintwork_modal_data.detail_type = null;
      this.paintwork_modal_data.condition_type = null;
      this.paintwork_modal_data.from_mcr = null;
      this.paintwork_modal_data.to_mcr = null;
      this.paintwork_modal_data.additional_mark = null;

      this.$modal.hide("paintwork_details_modal");
    },
    addPaintworkDetail() {
      let modalData = this.paintwork_modal_data;

      this.form.paintwork_details[modalData.section][modalData.detail_type].condition_type = modalData.condition_type;
      this.form.paintwork_details[modalData.section][modalData.detail_type].from_mcr = modalData.from_mcr;
      this.form.paintwork_details[modalData.section][modalData.detail_type].to_mcr = modalData.to_mcr;
      this.form.paintwork_details[modalData.section][modalData.detail_type].additional_mark = modalData.additional_mark;

      this.hidePaintworkDetailsModal();
    },

    async getFiles() {
      try {
        const car_tmp_files_response = await API.apiClient.get("/cars/tmp_files/" + this.form.car_tmp_id);

        this.car_tmp_files = car_tmp_files_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
    },

    async sendFile(file) {
      try {
        this.onProgressLoadFile = true;
        this.$refs.car_tmp_files.disable();

        const form_data = new FormData();
        form_data.append("car_tmp_id", this.form.car_tmp_id);
        form_data.append("file", file);

        await API.apiClient.post(this.dropzoneOptionsFiles.url, form_data).then(() => {
          this.onProgressLoadFile = false;
          this.getFiles();
          showSuccess();
        });

        this.$refs.car_tmp_files.removeFile(file);
      } catch (error) {
        this.onProgressLoadFile = false;
        errorHandler(error);
      }

      this.$refs.car_tmp_files.enable();
    },

    removeFile(file_id) {
      this.onProgress = true;

      API.apiClient.delete("/cars/tmp_files", {
        data: {
          file_id: file_id,
        },
      }).then(() => {
        this.getFiles();
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });
    },

    async saveCar() {
      await this.form.post("/cars/add").then(() => {
        showSuccess();
        setTimeout(() => this.$router.push("/cars"), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-box {
  position: relative;

  .btn {
    position: absolute;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0.8;

    &#front_bumper {
      top: 65%;
      left: 50%;
    }

    &#front_hood {
      top: 38%;
      left: 60%;
    }

    &#side_left_front_wing {
      top: 42%;
      left: 22%;
    }

    &#side_left_front_door {
      top: 58%;
      left: 42%;
    }

    &#side_left_back_door {
      top: 55%;
      left: 65%;
    }

    &#side_left_back_wing {
      top: 42%;
      left: 82%;
    }

    &#side_right_front_wing {
      top: 42%;
      left: 78%;
    }

    &#side_right_front_door {
      top: 55%;
      left: 58%;
    }

    &#side_right_back_door {
      top: 58%;
      left: 36%;
    }

    &#side_right_back_wing {
      top: 40%;
      left: 19%;
    }

    &#back_bumper {
      top: 62%;
      left: 50%;
    }

    &#back_tailgate_bottom {
      top: 44%;
      left: 42%;
    }

    &#roof_tailgate_top {
      top: 45%;
      left: 88%;
    }

    &#roof_main {
      top: 50%;
      left: 55%;
    }
  }
}

.image-box img {
  height: auto;
  width: 40%;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.image-files-box {
  position: relative;
}

.image-files-box img {
  width: 100%;
  height: auto;
}

.image-files-box .btn {
  position: absolute;
  bottom: 5%;
  right: 70%;
  transform: translate(-45%, -55%);
  -ms-transform: translate(-45%, -55%);
  background-color: #424656;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  opacity: 0.65;
  max-width: 45px;
}

.image-files-box .btn:hover {
  background-color: black;
}
</style>
